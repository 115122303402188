// https://v8.i18n.nuxtjs.org/getting-started/basic-usage
// https://vue-i18n.intlify.dev/api/general.html#createi18n

import nl from "./locales/nl.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "nl",
  availableLocales: ["nl"],
  allowComposition: true,
  messages: {
    nl,
  },
}));
